var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row position-relative",class:_vm.RoutePage == 'QuizFinal' ? 'm-0 p-0 w-100 big-div-quiz' : ' w-85 big-div',staticStyle:{"z-index":"100"},style:(_vm.getPageDesign())},[(_vm.pageDesign.pageStyles.jsCode)?_c('script2',[_vm._v(" "+_vm._s(_vm.pageDesign.pageStyles.jsCode)+" ")]):_vm._e(),(
      _vm.getImageAlign == 'no' &&
      _vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView) !== ''
    )?_c('div',{key:_vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView),staticClass:"bg lazyload",style:(_vm.getbackgroundImage(
        _vm.IsImageAdded,
        _vm.pageDesign.imageBlock,
        _vm.getImageAlign,
        _vm.isDesktopView
      ) + _vm.getBgDivStyles()),attrs:{"data-bg":_vm.MainImgSrc(_vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView))}}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLeftImage),expression:"showLeftImage"}],staticClass:"px-0 px-0",class:[
      {
        'col-12 col-lg-12 col-xs-12': !_vm.isDesktopView,
        'image-division col-6': _vm.isDesktopView,
      },
    ],style:(_vm.calculateImageWidth(
        _vm.pageDesign.imageBlock,
        _vm.isDesktopView,
        _vm.IsImageVariableWidthActive
      ))},[_c('div',{class:_vm.isDesktopView ? 'image-division' : ''},[_c('div',{style:(_vm.height100(_vm.isDesktopView))},[_c('LazyImage',{key:_vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView),class:[
            `pic-${_vm.getSelectedPage.order}`,
            _vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView) ==
            '/images/default_start_page.png'
              ? 'default-bg'
              : '',
          ],style:(_vm.getUniversalImageStyle(
              _vm.isDesktopView,
              _vm.pageDesign.imageBlock.style,
              _vm.IsImageVariableWidthActive,
              _vm.pageDesign.imageBlock
            ) +
            _vm.getImageRotation(_vm.pageDesign.imageBlock.style) +
            _vm.getImageRotation(_vm.pageDesign.imageBlock.style)),attrs:{"src":_vm.MainImgSrc(_vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView)),"alt":""}})],1)])]),_c('div',{class:[!_vm.IsImageAdded ? 'col-12' : _vm.imageAddedLayout],style:(_vm.getMainPaddingCss(_vm.pageDesign.pageStyles, _vm.isDesktopView) +
      _vm.getScrollCSS(
        _vm.pageDesign.pageStyles,
        _vm.isDesktopView,
        _vm.pageDesign.imageBlock.style
      ) +
      _vm.calculateContentWidth(
        _vm.pageDesign.imageBlock,
        _vm.isDesktopView,
        _vm.IsImageVariableWidthActive,
        _vm.pageDesign.pageStyles
      ))},[_c('div',_vm._l((_vm.pageDesign.blocksArray),function(pro,ind){return _c('div',{key:'preview' + ind,class:[
          'item-' + ind,
          pro.type === 'tooltip' ? 'tooltip-zIndex' : '',
        ],attrs:{"data-aos":_vm.animationType,"data-aos-duration":_vm.getAnimationSpeedinMillis,"data-aos-delay":_vm.AOSAnimationDelay(ind),"data-aos-anchor":`.item-${ind == 0 ? ind : ind - 1}`}},[_c('div',{staticClass:"content",staticStyle:{"position":"relative"},style:(_vm.getHeightWidth(pro.style)),attrs:{"id":"preview"}},[(pro.type == 'text')?_c('div',{style:(_vm.getColorCss(pro.style) +
              _vm.getPaddingCss(pro.style) +
              _vm.getMarginCss(pro.style, _vm.isDesktopView) +
              _vm.getColorWithOpacity(pro.style) +
              _vm.getTextRotate(pro.style) +
              _vm.getLineHeight(pro.style) +
              _vm.getTextAlign(pro.style) +
              _vm.getFontStyle(pro.style, _vm.isDesktopView)),domProps:{"innerHTML":_vm._s(_vm.getTextValue(pro.content))}}):_vm._e(),(pro.type == 'tooltip')?_c('div',{style:(`display:flex;justify-content: ${pro.style.position} ; `)},[_c('button',{style:(_vm.getButtonCss(pro.style, _vm.isDesktopView) +
                _vm.getCircularBtn(pro.isBtnRounded)),on:{"mouseenter":function($event){pro.isTooltipVisible = true},"mouseleave":function($event){pro.isTooltipVisible = false}}},[(pro.useIcon)?_c('span',[_c('BIcon',{attrs:{"icon":pro.selectedIcon}})],1):_c('span',{style:({
                  opacity: `${pro.style.textOpacity}%`,
                  fontSize: `${pro.style.fontSize}px`,
                })},[_vm._v(" "+_vm._s(pro.text)+" ")])]),(pro.isTooltipVisible)?_c('ToolTipComp',{attrs:{"tooltipData":pro,"isDesktopView":_vm.isDesktopView}}):_vm._e()],1):_vm._e(),(pro.type == 'timer')?_c('div',{style:(`display:flex;justify-content: ${pro.style.position} ; flex-direction:column;  `)},[_c('Timer',{attrs:{"pro":pro,"isDesktopView":_vm.isDesktopView,"isEditor":false},on:{"endCounter":_vm.endCounterAction}})],1):_vm._e(),(pro.type == 'button')?_c('div',{style:(`display:flex;justify-content: ${pro.style.position} ;  `)},[_c('button',{staticClass:"ButtonHover",style:(_vm.getButtonCss(pro.style, _vm.isDesktopView)),attrs:{"disabled":_vm.isLoadingQuestion},on:{"click":_vm.GoToNextPreviewPage}},[(_vm.isLoadingQuestion)?_c('b-spinner',{staticClass:"mx-1",attrs:{"small":"","label":"Small Spinner"}}):_vm._e(),_c('span',{style:({
                  opacity: `${pro.style.textOpacity}%`,
                  fontSize: `${pro.style.fontSize}px`,
                })},[_vm._v(" "+_vm._s(pro.text)+" ")]),_vm._v(" "+_vm._s(pro.style.alignItems)+" ")],1)]):_vm._e(),(pro.type == 'imageComp')?_c('div',[_c('InlineImageComp',{attrs:{"pro":pro,"isDesktopView":_vm.isDesktopView}})],1):_vm._e(),(pro.type == 'html')?_c('div',{style:(_vm.getMarginCss(pro.style, _vm.isDesktopView) +
              _vm.getColorWithOpacity(pro.style) +
              _vm.getTextRotate(pro.style) +
              _vm.getTextAlign(pro.style))},[_c('HtmlElement',{attrs:{"pro":pro,"isDesktopView":_vm.isDesktopView}})],1):_vm._e()])])}),0)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRightImage),expression:"showRightImage"}],staticClass:"px-0 px-0",class:[
      {
        'col-12 col-lg-12 col-xs-12': !_vm.isDesktopView,
        'image-division col-6': _vm.isDesktopView,
      },
    ],style:(_vm.calculateImageWidth(
        _vm.pageDesign.imageBlock,
        _vm.isDesktopView,
        _vm.IsImageVariableWidthActive
      ))},[_c('div',{class:_vm.isDesktopView ? 'image-division' : ''},[_c('div',{style:(_vm.height100(_vm.isDesktopView))},[_c('LazyImage',{key:_vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView),class:[
            `pic-${_vm.getSelectedPage.order}`,
            _vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView) ==
            '/images/default_start_page.png'
              ? 'default-bg'
              : '',
          ],style:(_vm.getUniversalImageStyle(
              _vm.isDesktopView,
              _vm.pageDesign.imageBlock.style,
              _vm.IsImageVariableWidthActive,
              _vm.pageDesign.imageBlock
            ) +
            _vm.getImageOpacity(_vm.pageDesign.imageBlock.style) +
            _vm.getImageRotation(_vm.pageDesign.imageBlock.style)),attrs:{"src":_vm.MainImgSrc(_vm.getImageSrc(_vm.pageDesign.imageBlock, _vm.isDesktopView)),"alt":""}})],1)])]),(_vm.getPreviewSettings && _vm.getPreviewSettings.showLogoStatus)?_c('div',{staticClass:"CreditLogo"},[_vm._m(0)]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-end pr-3 pb-3"},[_c('a',{attrs:{"href":"https://www.quizell.com?source=quiz","target":"_blank"}},[_c('img',{staticClass:"CreditLogoImg d-block d-sm-none",attrs:{"src":"https://images.quizell.com/website/creditMobileLogo.svg","alt":"Quizell"}}),_c('img',{staticClass:"d-none d-sm-block",attrs:{"src":"https://cdn.shopify.com/s/files/1/0620/9998/0532/files/Group_69111_2.svg?v=1660804067","alt":"Quizell"}})])])
}]

export { render, staticRenderFns }